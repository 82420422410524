import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import MQTTDebuggingCards from 'components/Advanced_User/INSTAR_MQTT_Broker/IntroMQTTDebugging';
import MQTTTopicsCards from 'components/Advanced_User/INSTAR_MQTT_Broker/IntroMQTTTopics';
import MQTT5TopicsCards from 'components/Advanced_User/INSTAR_MQTT_Broker/IntroMQTT5Topics';
import MQTTSoftwareCards from 'components/Advanced_User/INSTAR_MQTT_Broker/IntroMQTTSoftware';
import MQTTExternalCards from 'components/Advanced_User/INSTAR_MQTT_Broker/IntroMQTTExternal';
import PrimaryBox from 'components/Advanced_User/INSTAR_MQTT_Broker/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttUpdatesList';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT Broker",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/",
  "dateChanged": "2019-08-20",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Windows"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='INSTAR MQTT Server for INSTAR Full HD Camera Models' dateChanged='2019-08-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_MQTT.png' twitter='/images/Search/AU_SearchThumb_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-mqtt-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-broker",
        "aria-label": "instar mqtt broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Broker`}</h1>
    <h2 {...{
      "id": "the-mqtt-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-mqtt-interface",
        "aria-label": "the mqtt interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The MQTT Interface`}</h2>
    <ul>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1080p_Series/Network/MQTT/" mdxType="Link">INSTAR MQTT Service</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/" mdxType="Link">MQTT Debugging</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/" mdxType="Link">MQTT v5 API (WQHD Serie)</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/" mdxType="Link">MQTT v3 API (Full HD Series)</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/MQTT_vs_HTTP_API/" mdxType="Link">MQTT v3 vs. HTTP API (Full HD Series)</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/" mdxType="Link">Android Apps</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/" mdxType="Link">Home Assistant</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/OpenHAB2/" mdxType="Link">OpenHAB2</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/Node-RED/" mdxType="Link">Node-RED</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/" mdxType="Link">Athom Homey</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/Homematic_CCU3_and_RedMatic/" mdxType="Link">Homematic CCU3</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/ioBroker/" mdxType="Link">ioBroker</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/Loxone/" mdxType="Link">Loxone</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/homee/" mdxType="Link">homee</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/FHEM_House_Automation/" mdxType="Link">FHEM</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/HiveMQ/" mdxType="Link">HiveMQ</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/Mosquitto/" mdxType="Link">Mosquitto</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/Cloud_MQTT/" mdxType="Link">Cloud MQTT</Link>
      </li>
    </ul>
    <p>{`All camera functions of your INSTAR Full HD camera can be controlled through the `}<Link to="/1080p_Series_CGI_List/" mdxType="Link">{`HTTPS CGI interface`}</Link>{`. This is the interface that is used by the webUI and all INSTAR smartphone and desktop apps. But in the Smarthome and Internet-of-Things (IoT) realm there is another protocol that is widely in use and that offers a few advantages over the classic HTTP - this protocol is called MQTT `}<em>{` (Message Queuing Telemetry Transport)`}</em>{`. Adding an `}<Link to="/Web_User_Interface/1080p_Series/Network/MQTT/" mdxType="Link">{`MQTT interface`}</Link>{` * to our Full HD cameras makes adding those cameras to an existing Smarthome - `}<em>{`almost`}</em>{` - too easy :)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/2b013/MQTT_Broker_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2oEB/8QAFxABAQEBAAAAAAAAAAAAAAAAAgEREv/aAAgBAQABBQIbyoqZHn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAIQERMf/aAAgBAQAGPwJ2KWaf/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERMSFx/9oACAEBAAE/IVyo69dHhTrSZevT/9oADAMBAAIAAwAAABBzz//EABYRAAMAAAAAAAAAAAAAAAAAAAEQIf/aAAgBAwEBPxARf//EABYRAAMAAAAAAAAAAAAAAAAAAAEQIf/aAAgBAgEBPxA1f//EABoQAQADAQEBAAAAAAAAAAAAAAEAESExcYH/2gAIAQEAAT8Q8AhqrzSLISwnh8hi5N23P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/e4706/MQTT_Broker_01.avif 230w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/d1af7/MQTT_Broker_01.avif 460w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/7f308/MQTT_Broker_01.avif 920w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/e1c99/MQTT_Broker_01.avif 1380w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/d9e6b/MQTT_Broker_01.avif 1700w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/a0b58/MQTT_Broker_01.webp 230w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/bc10c/MQTT_Broker_01.webp 460w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/966d8/MQTT_Broker_01.webp 920w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/445df/MQTT_Broker_01.webp 1380w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/e5be3/MQTT_Broker_01.webp 1700w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/e83b4/MQTT_Broker_01.jpg 230w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/e41a8/MQTT_Broker_01.jpg 460w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/4d836/MQTT_Broker_01.jpg 920w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/89b2d/MQTT_Broker_01.jpg 1380w", "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/2b013/MQTT_Broker_01.jpg 1700w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f45bc79bbbbfdcd0b4e3ea1f3b8be6e5/4d836/MQTT_Broker_01.jpg",
              "alt": "INSTAR MQTT Server für INSTAR Full HD Kameramodelle",
              "title": "INSTAR MQTT Server für INSTAR Full HD Kameramodelle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In MQTT you control your camera by publishing updates to `}<strong>{`MQTT Topics`}</strong>{`. The value you want to update such a topic to is formatted in JSON and added to the `}<strong>{`Message Payload`}</strong>{`. So instead of sending a CGI command like `}<code>{`param.cgi?cmd=setmdattr&-name=1&-enable=\${"{val}"}`}</code>{` to either activate (val=1) or deactivate (val=0) Motion Detection Area 1 you publish this update to the topic `}<code>{`alarm/area1/enable`}</code>{` formatted like `}<code>{`{"{val:1}"}`}</code>{` or `}<code>{`{"{val:0}"}`}</code>{`.`}</p>
    <h3 {...{
      "id": "addressing-the-right-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#addressing-the-right-camera",
        "aria-label": "addressing the right camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Addressing the right Camera`}</h3>
    <p>{`To make sure that this update is received by the correct device on your MQTT network, we first have to prefix the topic with `}<code>{`instar`}</code>{` - this makes sure that your camera will try to match the update to an internal function - like updating the state of an detection area. Secondly, we have to state what camera we want to address by either saying `}<code>{`all`}</code>{` to update all INSTAR cameras on your network or picking a specific one by it`}{`"`}{`s MAC address, e.g. `}<code>{`000389888811`}</code>{`. You can find the MAC address of your camera under `}<Link to="/Web_User_Interface/1080p_Series/System/Info/" mdxType="Link">{`System/Overview`}</Link>{` - simply remove the colons, e.g. `}<strong>{`00:03:89:88:88:11`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/651aedaeceedfaccf7ba29fada9c863d/2b013/MQTT_Broker_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3ZCgf//EABcQAQEBAQAAAAAAAAAAAAAAAAABEiH/2gAIAQEAAQUC6mkf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAABAh/9oACAEBAAY/Air/xAAZEAADAAMAAAAAAAAAAAAAAAAAESEBMUH/2gAIAQEAAT8hF8zuIbp//9oADAMBAAIAAwAAABBzz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQACAgMBAAAAAAAAAAAAAAEAETFhIUGB8f/aAAgBAQABPxAC9+/YBYZbhwb3FRojbmf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/651aedaeceedfaccf7ba29fada9c863d/e4706/MQTT_Broker_02.avif 230w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/d1af7/MQTT_Broker_02.avif 460w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/7f308/MQTT_Broker_02.avif 920w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/e1c99/MQTT_Broker_02.avif 1380w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/d9e6b/MQTT_Broker_02.avif 1700w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/651aedaeceedfaccf7ba29fada9c863d/a0b58/MQTT_Broker_02.webp 230w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/bc10c/MQTT_Broker_02.webp 460w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/966d8/MQTT_Broker_02.webp 920w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/445df/MQTT_Broker_02.webp 1380w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/e5be3/MQTT_Broker_02.webp 1700w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/651aedaeceedfaccf7ba29fada9c863d/e83b4/MQTT_Broker_02.jpg 230w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/e41a8/MQTT_Broker_02.jpg 460w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/4d836/MQTT_Broker_02.jpg 920w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/89b2d/MQTT_Broker_02.jpg 1380w", "/en/static/651aedaeceedfaccf7ba29fada9c863d/2b013/MQTT_Broker_02.jpg 1700w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/651aedaeceedfaccf7ba29fada9c863d/4d836/MQTT_Broker_02.jpg",
              "alt": "INSTAR MQTT Server für INSTAR Full HD Kameramodelle",
              "title": "INSTAR MQTT Server für INSTAR Full HD Kameramodelle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you want to address the camera that is running your MQTT broker, you can also use the prefix `}<code>{`local`}</code>{` instead. The complete topic will then look like this:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`instar/all/alarm/area1/enable`}</em>{` /* - to address all INSTAR Full HD cameras on your MQTT network (all have to be connected to the same MQTT broker).`}</li>
      <li parentName="ul"><em parentName="li">{`instar/000389888811/alarm/area1/enable`}</em>{` - to address a camera with the (LAN) MAC address `}<strong parentName="li">{`00:03:89:88:88:11`}</strong>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`instar/local/alarm/area1/enable`}</em>{` /* - to address the cameras that runs the MQTT broker on your network.`}</li>
    </ul>
    <p>{`/* `}<em parentName="p">{`An example for the use of the `}<strong parentName="em">{`ALL Topic`}</strong>{` and `}<strong parentName="em">{`LOCAL Topic`}</strong>{` can be found in our`}</em>{` - `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/#multi-camera-setup"
      }}>{`MQTT Guide for Node-RED`}</a></p>
    <p>{`You can find the allowed payloads for each MQTT topic in the table below. The second table below that displays the corresponding CGI command to each topic (if you are already familiar with the HTTP API and are looking for a specific command).`}</p>
    <h3 {...{
      "id": "status-command-or-raw-topics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#status-command-or-raw-topics",
        "aria-label": "status command or raw topics permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Status, Command or Raw Topics`}</h3>
    <p>{`Once you update a `}<strong>{`COMMAND Topic`}</strong>{` like `}<code>{`instar/000389888811/alarm/area1/enable`}</code>{` you will see that you receive an update on the `}<strong>{`STATUS Topic`}</strong>{`  `}<code>{`instar/000389888811/status/alarm/area1/enable`}</code>{` once you camera received the command and updated its settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/089b82e7a1c9a532088deae2b96024f4/2b013/MQTT_Broker_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3QUH/8QAFhAAAwAAAAAAAAAAAAAAAAAAARAx/9oACAEBAAEFAkJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGRAAAwADAAAAAAAAAAAAAAAAAAERIUFR/9oACAEBAAE/IXN0w+iSD//aAAwDAQACAAMAAAAQ+8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEBAAIDAAAAAAAAAAAAAAABEQBBITFR/9oACAEBAAE/EEOR21cIIApu5K8Z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/089b82e7a1c9a532088deae2b96024f4/e4706/MQTT_Broker_03.avif 230w", "/en/static/089b82e7a1c9a532088deae2b96024f4/d1af7/MQTT_Broker_03.avif 460w", "/en/static/089b82e7a1c9a532088deae2b96024f4/7f308/MQTT_Broker_03.avif 920w", "/en/static/089b82e7a1c9a532088deae2b96024f4/e1c99/MQTT_Broker_03.avif 1380w", "/en/static/089b82e7a1c9a532088deae2b96024f4/d9e6b/MQTT_Broker_03.avif 1700w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/089b82e7a1c9a532088deae2b96024f4/a0b58/MQTT_Broker_03.webp 230w", "/en/static/089b82e7a1c9a532088deae2b96024f4/bc10c/MQTT_Broker_03.webp 460w", "/en/static/089b82e7a1c9a532088deae2b96024f4/966d8/MQTT_Broker_03.webp 920w", "/en/static/089b82e7a1c9a532088deae2b96024f4/445df/MQTT_Broker_03.webp 1380w", "/en/static/089b82e7a1c9a532088deae2b96024f4/e5be3/MQTT_Broker_03.webp 1700w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/089b82e7a1c9a532088deae2b96024f4/e83b4/MQTT_Broker_03.jpg 230w", "/en/static/089b82e7a1c9a532088deae2b96024f4/e41a8/MQTT_Broker_03.jpg 460w", "/en/static/089b82e7a1c9a532088deae2b96024f4/4d836/MQTT_Broker_03.jpg 920w", "/en/static/089b82e7a1c9a532088deae2b96024f4/89b2d/MQTT_Broker_03.jpg 1380w", "/en/static/089b82e7a1c9a532088deae2b96024f4/2b013/MQTT_Broker_03.jpg 1700w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/089b82e7a1c9a532088deae2b96024f4/4d836/MQTT_Broker_03.jpg",
              "alt": "INSTAR MQTT Server für INSTAR Full HD Kameramodelle",
              "title": "INSTAR MQTT Server für INSTAR Full HD Kameramodelle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If your application doesn`}{`"`}{`t support sending command payloads in the JSON format, you can use the `}<strong>{`RAW Topic`}</strong>{` by adding `}<code>{`raw`}</code>{` to the end of the topic and use the value as a string directly:`}</p>
    <ul>
      <li parentName="ul">
        <code>instar/000389888811/alarm/area1/enable/raw</code> - the message payload here is simply either <code>1</code> or <code>0</code> <strong>instead of</strong> <code>{"{val:1}"}</code> or <code>{"{val:0}"}</code>.
      </li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "update-your-first-mqtt-topic",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#update-your-first-mqtt-topic",
        "aria-label": "update your first mqtt topic permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Update your first MQTT Topic`}</h3>
    <p>{`To update a topic you can use a desktop software like the `}<Link to="/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/" mdxType="Link">{`MQTT Explorer or MQTT.fx`}</Link>{` for Windows, macOS or LINUX. Or one of the many available `}<Link to="/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/" mdxType="Link">{`Smartphone Apps`}</Link>{`. These allow you to try out the MQTT API without the need of additional Hardware (Smarthome Gateways) and quickly debug your MQTT network.`}</p>
    <h2 {...{
      "id": "configure-and-test-your-mqtt-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configure-and-test-your-mqtt-broker",
        "aria-label": "configure and test your mqtt broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure and test your MQTT Broker`}</h2>
    <MQTTDebuggingCards mdxType="MQTTDebuggingCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "mqtt-v5-topics-und-message-payloads-wqhd-serie",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-v5-topics-und-message-payloads-wqhd-serie",
        "aria-label": "mqtt v5 topics und message payloads wqhd serie permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT v5 Topics und Message Payloads (WQHD Serie)`}</h2>
    <MQTT5TopicsCards mdxType="MQTT5TopicsCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "mqtt-v3-topics-message-payloads-and-cgi-commands-full-hd-serie",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-v3-topics-message-payloads-and-cgi-commands-full-hd-serie",
        "aria-label": "mqtt v3 topics message payloads and cgi commands full hd serie permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT v3 Topics, Message Payloads and CGI Commands (Full HD Serie)`}</h2>
    <MQTTTopicsCards mdxType="MQTTTopicsCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "compatible-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#compatible-software",
        "aria-label": "compatible software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Compatible Software`}</h2>
    <MQTTSoftwareCards mdxType="MQTTSoftwareCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "external-mqtt-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#external-mqtt-broker",
        "aria-label": "external mqtt broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`External MQTT Broker`}</h2>
    <p>{`All INSTAR Full HD cameras come with a full MQTT Broker. It is `}<strong>{`not necessary`}</strong>{` to install an external broker to use the INSTAR MQTT Service. But there are situations where you might want to use e.g. an already existing broker on your network. The following examples show you how to set up HiveMQ, Mosquitto or the Internet-based CloudMQTT service to work with your INSTAR camera.`}</p>
    <MQTTExternalCards mdxType="MQTTExternalCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      